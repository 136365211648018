<template>
	<v-container fluid style="max-width: 1600px">
		<div class="d-flex">
			<v-img src="@/assets/sponsors/eic/logo.png" contain :max-width="logoMaxWidth" class="ma-auto" />
			<div class="mx-auto align-self-center">
				<h5 class="text-center text-h5">
					{{ $t('sponsors.officialSponsor') }}
				</h5>
				<h2 class="text-center text-h2" v-if="$vuetify.breakpoint.lgAndUp">Enginyers Industrials de Catalunya</h2>
			</div>
		</div>
		<h4 class="text-center text-h4 my-2" v-if="$vuetify.breakpoint.smAndDown">Enginyers Industrials de Catalunya</h4>
		<v-row>
			<v-col cols="12" lg="3" sm="6" xs="12">
				<v-card class="ma-1 px-7" color="indigo darken-1" height="200px" rounded="lg">
					<div class="text-center">
						<v-icon x-large class="my-4"> mdi-school </v-icon>
					</div>
					<h5 class="text-center font-weight-bold text-h5">Formació</h5>
					<p class="text-center text-body-1">Cursos d’especialització i formació contínua</p>
					<h6 class="text-center font-weight-bold text-h6">+ INFO</h6>
				</v-card>
			</v-col>
			<v-col cols="12" lg="3" sm="6" xs="12">
				<v-card class="ma-1 px-7" color="indigo darken-1" height="200px" rounded="lg">
					<div class="text-center">
						<v-icon x-large class="my-4"> mdi-card-account-details </v-icon>
					</div>
					<h5 class="text-center font-weight-bold text-h5">Ocupació</h5>
					<p class="text-center text-body-1">Acompanyament professional al llarg de tota la vida laboral</p>
					<h6 class="text-center font-weight-bold text-h6">+ INFO</h6>
				</v-card>
			</v-col>
			<v-col cols="12" lg="3" sm="6" xs="12">
				<v-card class="ma-1 px-7" color="indigo darken-1" height="200px" rounded="lg">
					<div class="text-center">
						<v-icon x-large class="my-4"> mdi-file-document-multiple </v-icon>
					</div>
					<h5 class="text-center font-weight-bold text-h5">Certificació</h5>
					<p class="text-center text-body-1">Garantia d'identitat, titulació i habilitació professional</p>
					<h6 class="text-center font-weight-bold text-h6">+ INFO</h6>
				</v-card>
			</v-col>
			<v-col cols="12" lg="3" sm="6" xs="12">
				<v-card class="ma-1 px-7" color="indigo darken-1" height="200px" rounded="lg">
					<div class="text-center">
						<v-icon x-large class="my-4"> mdi-information-variant </v-icon>
					</div>
					<h5 class="text-center font-weight-bold text-h5">Normativa</h5>
					<p class="text-center text-body-1">Informació de legislació i normes tècniques</p>
					<h6 class="text-center font-weight-bold text-h6">+ INFO</h6>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12" lg="6" sm="12">
				<v-card rounded="xl">
					<v-card-title>
						Gestió de la pròpia carrera professional
						<v-spacer />
						<span class="text-body-2"><v-icon>mdi-calendar</v-icon> {{ formatDate(new Date(2021, 2, 15)) }}</span>
					</v-card-title>
					<v-card-subtitle>
						Impartida per <a href="https://www.linkedin.com/in/ignasirafel/" class="text-decoration-none">Ignasi Rafel</a>. Headhunter. Soci director.
						Talman Group
					</v-card-subtitle>
					<v-card-text>
						<h5 class="text-h5">What is Lorem Ipsum?</h5>
						<p class="text-justify">
							Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
							since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
							five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
							release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
							including versions of Lorem Ipsum.
						</p>
						<h5 class="text-h5">Why do we use it?</h5>
						<p class="text-justify">
							It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point
							of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here',
							making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model
							text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years,
							sometimes by accident, sometimes on purpose (injected humour and the like).
						</p>
					</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn text color="red accent-4"> Sol·licitar més informació </v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
			<v-col cols="12" lg="6" sm="12">
				<v-card rounded="xl">
					<v-card-title>
						Què valora una empresa quan contracta enginyers?
						<v-spacer />
						<span class="text-body-2"><v-icon>mdi-calendar</v-icon> {{ formatDate(new Date(2021, 3, 18)) }}</span>
					</v-card-title>
					<v-card-subtitle>
						Impartida per
						<a href="https://www.linkedin.com/in/silvia-tordera-rodr%C3%ADguez-059bb5a9/" class="text-decoration-none">Sílvia Tordera</a>. Manager
						Enginyers i Tècnics. Page Personnel Talman Group
					</v-card-subtitle>
					<v-card-text>
						<h5 class="text-h5">What is Lorem Ipsum?</h5>
						<p class="text-justify">
							Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
							since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
							five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
							release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
							including versions of Lorem Ipsum.
						</p>
						<h5 class="text-h5">Why do we use it?</h5>
						<p class="text-justify">
							It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point
							of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here',
							making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model
							text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years,
							sometimes by accident, sometimes on purpose (injected humour and the like).
						</p>
					</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn text color="red accent-4"> Sol·licitar més informació </v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: 'Enginyers Industrials de Catalunya',
		}
	},
	computed: {
		logoMaxWidth() {
			if (this.$vuetify.breakpoint.xlAndUp) return 300
			else if (this.$vuetify.breakpoint.lgAndUp) return 300
			else return 150
		},
	},
	methods: {
		formatDate(date) {
			return this.$moment(date).locale(this.$i18n.locale).format('D MMM')
		},
	},
}
</script>
